import React from 'react'
import SEO from "../../components/seo"
import AuthLayout from '../../components/Layout/AuthLayout'
import PasswordReset from '../../components/Layout/AuthLayout/ResetPassword'
import { useLocation } from "@reach/router"

const ResetPassword = () => {

    const location = useLocation()
    return (
        <>
        <SEO title="Home" />
        <AuthLayout>
            <PasswordReset location={location}/>
        </AuthLayout>
            
        </>
    )
}

export default ResetPassword
