import React, { useState, useContext } from "react"
import { Formik, Form, ErrorMessage } from "formik"
import FormErrors from "../../../FormErrors"
import * as Yup from "yup"
import { FormControl, Box, TextField, Typography } from "@material-ui/core"
import Button from "../../../Button"
import clsx from "clsx"
import queryString from "query-string"
import { useStyles } from "./style"
import AuthContext from "../../../../context/auth/authContext"

const ResetPassword = ({ location }) => {
  const classes = useStyles()
  const [passwords, showPasswords] = useState({
    password1: false,
    password2: false,
  })

  const { password1, password2 } = passwords
  const { loading, resetPassword} = useContext(AuthContext)

  const handleClickShowPassword = (name, value) => {
    showPasswords({ ...passwords, [name]: value })
  }

  const handleMouseDownPassword = e => {
    e.preventDefault()
  }
  const { userId } = queryString.parse(location.search)
  const token = location.search.split("&")[1]
  const initialValues = {
    userId,
    token: token ? token.slice(6) : "",
    password: "",
    password2: "",
  }
  const validationSchema = Yup.object({
    password: Yup.string().required("Password is empty").min(8, "Password must not be less than 8 characters").matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/,
      "Password must contain at least one Uppercase, one Lowercase, one Number and one special character"),
    password2: Yup.string()
      .required("Re-enter Password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  })

  const onSubmit = values => {
    const { password, password2, userId, token } = values
    values = {
      userId,
      token,
      newPassword: password,
      confirmPassword: password2
    }
    resetPassword(values)
  }

  return (
    <Box>
      <Typography align="center" className={classes.title}>
        Reset Password
      </Typography>
      <Typography align="center" className={classes.subtitle}>
        Enter a new password
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, getFieldProps }) => (
          <Form noValidate autoComplete="off" className={classes.form}>
            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Password</Typography>
              <TextField
                name="password"
                id="password1"
                {...getFieldProps("password")}
                variant="outlined"
                type={password1 ? "text" : "password"}
                size="small"
                InputProps={{
                  endAdornment: (
                    <Typography
                      position="end"
                      className={clsx(classes.actionText, classes.text)}
                      onClick={() =>
                        handleClickShowPassword("password1", !password1)
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {password1 ? "Hide" : "Show"}
                    </Typography>
                  ),
                }}
              />
              <ErrorMessage name="password" component={FormErrors} />
            </FormControl>

            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>
                Confirm Password
              </Typography>
              <TextField
                name="password2"
                id="password2"
                {...getFieldProps("password2")}
                variant="outlined"
                type={password2 ? "text" : "password"}
                size="small"
                InputProps={{
                  endAdornment: (
                    <Typography
                      position="end"
                      className={clsx(classes.actionText, classes.text)}
                      onClick={() =>
                        handleClickShowPassword("password2", !password2)
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {password2 ? "Hide" : "Show"}
                    </Typography>
                  ),
                }}
              />
              <ErrorMessage name="password2" component={FormErrors} />
            </FormControl>

            <FormControl className={classes.submit}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                loading={loading}
              >
                Reset Password
              </Button>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default ResetPassword
